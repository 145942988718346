<template>
  <div class="container">
    <div class="graytitle">{{ $t("cart.check_title") }}</div>
    <!-- 收货地址 -->
    <div class="address">
      <div @click="$router.push('/my/address?url=chichek')" style="cursor: pointer">
        <span class="iconfont icon-dingwei"></span>
        <div class="adDetail" v-if="curAddress">
          <p class="blod name">{{ curAddress.name }}</p>
          <p>{{ curAddress.tel }}，{{ curAddress.email }}</p>
          <p>
            {{ curAddress.province }}{{ curAddress.city }}{{ curAddress.area
            }}{{ curAddress.address }}
            <span v-if="curAddress.zcode">，{{ curAddress.zcode }}</span>
          </p>
        </div>
        <div class="noaddress" v-else>{{ $t("cart.please_address") }}</div>
        <span class="iconfont icon-jiantou1"></span>
      </div>
    </div>
    <div class="cartList">
      <div class="cItem" v-for="(item, i) in list" :key="i" v-show="allShow ? true : i < 5">
        <div class="cimg">
          <div @click="goodsToUrl(item)">
            <q-img :src="item.photo" alt :ratio="6 / 8" />
          </div>
        </div>
        <div class="ctxt">
          <p class="brand blod">{{ item.generalattributename }}</p>
          <p class="name">
            {{ $i18n.locale == "en" ? item.titleen : item.title }}
          </p>
          <p class="code">{{ item.code }}</p>
          <p>
            {{ $t("cart.goods_color") }}
            <span class="blod">{{
              $i18n.locale == "en" ? item.coloren : item.color
            }}</span>
          </p>
          <p v-if="item.size != '01'">
            {{ $t("cart.goods_size") }}
            <span class="blod">{{ item.size }}</span>
          </p>
          <p>
            {{ $t("cart.nums") }}
            <span class="blod">{{ item.quantity }}</span>
          </p>
          <p>
            {{ $t("cart.goods_price") }}
            <span class="blod line-through" v-if="item.isdiscount == 1">{{ currency }}{{ item.oprice }}</span>
            <span class="blod">{{ currency }}{{ item.sprice }}</span>
          </p>
          <p>
            {{ $t("cart.total") }}
            <span class="blod">
              {{ currency }}{{
                (parseFloat(item.sprice) * parseFloat(item.quantity)).toFixed(2)
              }}
            </span>
          </p>
        </div>
        <div class="num">
          <div v-for="(jtem, jnx) in item.conpon" :key="jnx">
            <div>
              {{
                jtem.isdiscount == 1
                ? `${$t("cart.dis_j")}:`
                : `${$t("cart.credit")}:`
              }}
            </div>
            <div>
              <span v-if="jtem.isdiscount == 1">{{ $t("cart.reduction") }} - {{ jtem.zhekou }}%</span>
              <span v-else>{{ $t("cart.reduction") }} - {{ currency }}{{ jtem.zhekou }}</span>
            </div>
          </div>
          <div v-if="item.disPrice">{{ currency }}{{ item.disPrice }}</div>
        </div>
      </div>
      <div class="look-all" v-if="list.length > 5" @click="allShow = !allShow">
        {{ allShow ? $t("cart.hide_all") : $t("cart.show_all") }}
      </div>
      <!-- 优惠券选择 -->
      <div class="discount" v-if="isUseConpon">
        <div class="dc_item">
          <q-input v-model="discountCode1" dense :label="$t('cart.discount_code')">
            <template v-slot:append>
              <q-btn size="sm" :label="$t('cart.confirm')" color="primary" dense @click="SelectBtn(discountCode1, 1)" />
            </template>
          </q-input>
        </div>
        <div class="dc_item">
          <q-input v-model="discountCode2" dense :label="$t('cart.credit')">
            <template v-slot:append>
              <q-btn size="sm" dense color="primary" :label="$t('cart.confirm')" @click="SelectBtn(discountCode2, 2)" />
            </template>
          </q-input>
        </div>
        <div class="dc_item" style="text-align: right;" v-if="isBalanceShow">
          <q-checkbox left-label size="sm" v-model="isBalance"
            :label="`${$t('cart.isUseBalance')}${currency}${abalance}`" />
        </div>
      </div>
      <div class="statistics">
        <div>
          <span></span>
          <span style="text-align: right">
            <div class="blod" :class="freeDeliver.guid || freeDeliver.prices >= 0 ? 'cros' : ''">
              {{ $t("cart.coupontitle") }}{{ currency }}{{ freeDeliver.price }}
            </div>
            <div class="blod" v-if="freeDeliver.prices >= 0" :class="freeDeliver.guid ? 'cros' : ''">
              {{ $t("cart.coupontitles") }}{{ currency }}{{ freeDeliver.prices }}
            </div>
          </span>
        </div>
        <div>
          <span>{{ $t("cart.sum") }}</span>
          <span class="blod">{{ totalinfo.qty }}{{ $t("cart.num") }}</span>
        </div>
        <template v-if="disCountInfo.length">
          <div>
            <span>{{ $t("cart.Total_Product") }}</span>
            <span class="blod">{{ currency }}{{ totalinfo.goodsTotalDis }}</span>
          </div>
          <div>
            <span>{{ $t("cart.total") }}</span>
            <span class="blod">{{ currency }}{{ totalinfo.discountTotal }}</span>
          </div>
          <div style="text-transform: uppercase;">
            <span>{{ $t("cart.s_pay_price") }}</span>
            <span v-show="isBalance">{{ currency }}{{ balanceTotal }}<span class="p-tip">({{ $t("cart.y_pay_price") }}:
                {{ currency }}{{ totalinfo.discountTotal }} - {{ $t("cart.Use_balance") }}：{{ currency }}{{ balanceTotal === 0
                  ?
                  totalinfo.discountTotal : abalance
                }})</span></span>
            <span v-show="!isBalance">{{ currency }}{{ totalinfo.discountTotal }}<span class="p-tip">({{
              $t("cart.y_pay_price") }}:
                {{ currency }}{{ totalinfo.discountTotal }} - {{ $t("cart.Use_balance") }}：{{ currency }}0.00)</span></span>
          </div>
          <div>
            <span>{{ $t("cart.get") }}{{ $t("cart.point") }}</span>
            <span v-show="isBalance">{{ parseInt(balanceTotal) }}</span>
            <span v-show="!isBalance">{{ totalinfo.discountIntegral }}</span>
          </div>
        </template>
        <template v-else>
          <div>
            <span>{{ $t("cart.Total_Product") }}</span>
            <span class="blod">{{ currency }}{{ totalinfo.goodsTotal }}</span>
          </div>
          <div>
            <span>{{ $t("cart.total") }}</span>
            <span class="blod">{{ currency }}{{ totalinfo.total }}</span>
          </div>
          <div style="text-transform: uppercase;">
            <span>{{ $t("cart.s_pay_price") }}</span>
            <span v-show="isBalance">{{ currency }}{{ balanceTotal }}<span class="p-tip">({{ $t("cart.y_pay_price") }}:
                {{ currency }}{{ totalinfo.total }} - {{ $t("cart.Use_balance") }}：{{ currency }}{{ balanceTotal === 0 ?
                  totalinfo.total : abalance
                }})</span></span>
            <span v-show="!isBalance">{{ currency }}{{ totalinfo.total }}<span class="p-tip">({{ $t("cart.y_pay_price") }}:
                {{ currency }}{{ totalinfo.total }} - {{ $t("cart.Use_balance") }}：{{ currency }}0.00)</span></span>
          </div>
          <div>
            <span>{{ $t("cart.get") }}&nbsp;{{ $t("cart.point") }}</span>
            <span v-show="isBalance">{{ parseInt(balanceTotal) }}</span>
            <span v-show="!isBalance">{{ totalinfo.total * $route.query.integralScale }}</span>
          </div>
        </template>
      </div>
      <div class="btnBox">
        <q-btn size="lg" class="full-width" color="primary" :label="$t('cart.play')" @click="goPay()" />
      </div>
    </div>
  </div>
</template>

<script>
import { address } from '@/api/my'
import { getOrderDisApi } from '@/api/goods'
import { currencyFormat } from '@/utils/filterData.js'
import { goodsToUrl } from '@/utils/goodsToUrl'
import _ from 'lodash'
import currency from 'currency.js'
import store from '@/store'

export default {
  data() {
    return {
      list: [],
      curAddress: '',
      discountCode1: '',
      discountCode2: '',
      goodsGuid: '',
      popshow: false,
      addressList: [],
      disCountInfo: [],
      totalinfo: {
        qty: 0,
        total: 0, // 普通总计
        discountTotal: 0, // 使用优惠券后的总计
        discountIntegral: 0,
        goodsTotal: 0,
        goodsTotalDis: 0
      },
      addressShow: false,
      freeDeliver: [],
      allShow: false,
      isUseConpon: true,
      isBalance: false,
      abalance: '',
      isBalanceShow: false,
      balanceTotal: ''
    }
  },
  computed: {
    currency() {
      return store.state.global.currency
    }
  },
  watch: {
    discountCode1() {
      this.disCountInfo[0] = ''
      this.PriceChange()
    },
    discountCode2() {
      this.disCountInfo[1] = ''
      this.PriceChange()
    },
    isBalance() {
      const price = this.totalinfo.discountTotal ? this.totalinfo.discountTotal : this.totalinfo.total
      this.balanceTotal = price - this.abalance < 0 ? 0 : currency(price).subtract(this.abalance)
    }
  },
  methods: {
    async getAddress() {
      const res = await address({
        type: 0,
        coupon: 1
      })
      // 获取配送信息
      this.freeDeliver = res.listc[0]
      this.addressList = res.list
      if (res.abalance > 0) {
        this.isBalanceShow = true
        this.abalance = res.abalance
      }
      // 获取默认地址做为当前地址
      this.curAddress = res.list.find(item => {
        return item.defaults === true
      })
      // 没有默认地址则拿第一个地址作为当前地址
      if (this.curAddress === undefined) {
        this.curAddress = res.list[0]
      }
      // 总计加上运费
      if (res.listc.length && this.freeDeliver.guid === '') {
        if (this.freeDeliver.prices >= 0) {
          this.totalinfo.total += parseFloat(this.freeDeliver.prices)
        } else {
          this.totalinfo.total += parseFloat(this.freeDeliver.price)
        }
      }
    },
    /**
     * 选择优惠券
     * @param index 为1是第一行，2是第二行
     */
    async SelectBtn(val, index) {
      if (val === '') {
        this.$q.notify(this.$t('cart.enter_code'))
        return
      }
      const res = await getOrderDisApi({
        couponcode: val,
        shopguid: this.goodsGuid,
        type: index
      })
      if (res.status === 1) {
        this.disCountInfo[index - 1] = res
        const n = _.find(this.disCountInfo, { coupontype: 12 })
        if (n === undefined) {
          this.disCountInfo = []
          this.disCountInfo[index - 1] = res
        }
        // 改变优惠后的价格
        this.PriceChange()
        this.$q.notify(this.$t('cart.use_success'))
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
    },
    // 选择地址
    changeAddress(index) {
      this.addressList.forEach(item => {
        item.defaults = false
      })
      this.addressList[index].defaults = true
      this.curAddress = this.addressList[index]
    },
    // 下单
    goPay() {
      if (this.curAddress === '' || this.curAddress === undefined) {
        this.$q.notify(this.$t('cart.enter_address'))
        return
      }
      const obj = {
        couponguid: '', // 普通优惠券
        couponguid2: this.freeDeliver.guid, // 运费优惠券
        couponguid3: '' // 弹框优惠券
      }
      if (_.get(this.disCountInfo, '[1].coupontype') === 12) {
        obj.couponguid = _.get(this.disCountInfo, '[0].VIPdiscountguid') || ''
        obj.couponguid3 = _.get(this.disCountInfo, '[1].VIPdiscountguid')
      } else {
        obj.couponguid =
          _.get(this.disCountInfo, '[0].VIPdiscountguid') ||
          _.get(this.disCountInfo, '[1].VIPdiscountguid')
      }
      let price = ''
      if (this.isBalance) {
        price = this.balanceTotal
      } else {
        price = this.totalinfo.discountTotal ? this.totalinfo.discountTotal : this.totalinfo.total
      }
      this.$router.push({
        path: '/payment',
        query: {
          price,
          mark: this.goodsGuid,
          aguid: this.curAddress.guid,
          ...obj,
          ...(this.isBalance && { balancep: this.abalance })
        }
      })
    },
    // 优惠券变化改变金额
    PriceChange() {
      this.isBalance = false
      this.totalinfo.discountTotal = 0
      this.list.forEach(item => {
        item.conpon = []
        for (const i in this.disCountInfo) {
          const indexs = _.find(this.disCountInfo[i].item, {
            guid: item.spguid
          })
          if (indexs) {
            item.conpon.push(indexs)
          }
        }
        item.disPrice = item.sprice * item.quantity
        item.conpon.forEach(j => {
          if (j.isdiscount === '1') {
            item.disPrice =
              item.disPrice - j.sprice * item.quantity * (j.zhekou / 100)
          } else {
            item.disPrice = item.disPrice - j.zhekou
          }
        })
        // 小数后两位
        item.disPrice = _.round(item.disPrice, 2)
        this.totalinfo.discountTotal = _.round(
          this.totalinfo.discountTotal + item.disPrice,
          2
        )
      })
      this.totalinfo.goodsTotalDis = this.totalinfo.discountTotal
      // 判断是否有免费配送，没有就加上运费
      if (this.freeDeliver.guid === '') {
        if (this.freeDeliver.prices >= 0) {
          this.totalinfo.discountTotal += parseFloat(this.freeDeliver.prices)
        } else {
          this.totalinfo.discountTotal += parseFloat(this.freeDeliver.price)
        }
      }
      // 积分向下取整
      this.totalinfo.discountIntegral = _.floor(this.totalinfo.discountTotal)
    }
  },
  mounted() {
    // 没有订单信息返回购物车
    if (this.$store.state.chichekInfo === '') {
      this.$router.push('/cart')
    } else {
      this.list = JSON.parse(this.$store.state.chichekInfo)
      this.getAddress()
      const arr = []
      let length = 0
      this.list.forEach(item => {
        if (item.isdiscount) {
          length++
        }
        arr.push(item.spguid)
        this.totalinfo.qty += item.quantity
        this.totalinfo.total += item.sprice * item.quantity
        this.totalinfo.goodsTotal += item.sprice * item.quantity
      })
      if (length === this.list.length) {
        this.isUseConpon = false
      }
      this.goodsGuid = arr.join(',')
    }
  },
  setup() {
    return {
      currencyFormat,
      goodsToUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.graytitle {
  width: 100%;
  height: 41px;
  line-height: 41px;
  text-align: center;
  font-weight: bold;
  background-color: #e5e5e5;
}

.address {
  min-height: 146px;
  border-bottom: 29px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .noaddress {
    display: flex;
    align-items: center;
  }

  &>div {
    width: 930px;
    margin: 0 auto;
    display: flex;
    position: relative;

    .icon-dingwei {
      font-size: 25px;
    }

    .adDetail {
      margin-left: 10px;

      p {
        margin: 0;
      }

      .name {
        margin-bottom: 10px;
      }
    }

    .icon-jiantou1 {
      position: absolute;
      right: 0px;
      top: 50%;
      font-size: 30px;
      margin-top: -15px;
    }
  }
}

.cartList {
  max-width: 978px;
  margin: 0 auto;
  padding: 0 10px;

  .cItem {
    border-bottom: 1px solid #cbcbcb;
    display: flex;
    align-items: center;
    padding: 10px 0;
    position: relative;

    .cimg {
      margin-right: 20px;
      width: 125px;
      min-width: 125px;
      cursor: pointer;
    }

    .ctxt {
      p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      .code {
        margin-bottom: 10px;
      }

      .brand {
        font-size: 18px;
      }
    }

    .num {
      position: absolute;
      right: 0px;
      bottom: 10px;
      font-size: 12px;
      text-align: right;
      display: flex;
      flex-direction: column;

      &>span {
        margin-right: 20px;
      }

      .red {
        font-size: 16px;
        color: #ee0000;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  .discount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 0;

    .dc_item {
      width: 350px;
      padding: 5px 0;
      font-size: 12px;
    }
  }

  .statistics {
    margin-top: 20px;

    .cros {
      text-decoration: line-through;
    }

    &>div {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }

    .p-tip {
      font-size: 12px;
      color: #666;
      margin-left: 5px;
    }
  }
}

.btnBox {
  width: 300px;
  text-align: center;
  margin: 30px auto;
}

.look-all {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0;
}</style>
